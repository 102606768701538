import PropTypes from 'prop-types';
import { useRouter } from 'next-translate-routes';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

// Utils
import { cn } from 'utils';
import { getSlugResidence } from '../../../src/utils/pageSlug';

// Style
import styles from './MapResidencesBlock.module.css';

// Hooks
import useResponsiveQueries from '../../../src/hooks/useResponsiveQueries';

// Constants
import { URL_SEARCH_PAGES } from '../../../src/constants';

// Components
import ResidenceCard from '../../atoms/ResidenceCard/ResidenceCard';
import CardsCarousel from '../../atoms/CardsCarousel/CardsCarousel';
import Button from '../../atoms/Button/Button';

function MapResidencesBlock({
  residences, theme, mapImage,
}) {
  const {
    isLaptop,
  } = useResponsiveQueries();
  const { locale } = useRouter();
  const { t } = useTranslation();

  return (
    <div className={cn([styles.wrapper, styles[theme]])}>
      <div className={styles.container}>
        <div className={styles.map}>
          <div className={styles.map}>
            <img src={mapImage.url} alt="" className={styles.mapImage} />
          </div>
        </div>
        <div className={styles.residencesWrapper}>
          <div className={styles.residencesContainer}>
            <div className={styles.residences}>
              {!isLaptop ? (
                residences.map((residence) => {
                  const residenceData = residence?.data?.residence?.data || {};
                  return (
                    <ResidenceCard
                      key={residenceData.pmsId}
                      name={residenceData.nameShort}
                      address={residenceData.address}
                      price={t('residencesBlock.price', { price: residenceData.minPrice })}
                      media={residenceData.media}
                      slug={getSlugResidence(locale, residence?.data?.slug)}
                      theme={theme}
                      TitleTag="h2"
                      orientation="horizontal"
                    />
                  );
                })
              ) : (
                <CardsCarousel list={residences} theme={theme} uniqueKey="slug">
                  {({ data }) => {
                    const cleanedLink = getSlugResidence(locale, data?.slug);
                    const residence = data?.residence?.data;

                    return (
                      <ResidenceCard
                        key={residence?.pmsId}
                        name={residence?.nameShort}
                        address={residence?.address}
                        price={t('residencesBlock.price', { price: residence?.minPrice })}
                        media={residence?.media}
                        slug={cleanedLink}
                        theme={theme}
                        TitleTag="h2"
                      />
                    );
                  }}
                </CardsCarousel>
              )}
            </div>
            <Link href={URL_SEARCH_PAGES[locale]} className={styles.link}>
              <Button
                CTAType="secondary"
                outline
                usageContext="light"
                className={styles.ctaSearch}
                icon="arrowRight"
                iconPosition="right"
              >
                {t('cardResidencesBlock.search')}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

MapResidencesBlock.propTypes = {
  residences: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.shape({}),
      address: PropTypes.string,
      price: PropTypes.string,
      media: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
      }),
      slug: PropTypes.string,
      theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
    }),
  ).isRequired,
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']).isRequired,
  mapImage: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default MapResidencesBlock;
